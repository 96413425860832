<template lang="pug">
div.container
  Header
  .resource
    .label Read this
    a.title(:href="resourceUrl", target="_blank") {{title}}
    .instructions Read the article.  Then, take the quiz to test what you learned.
  button.read(v-on:click="openResource") Read article
  button.next(v-on:click="next") Take the Quiz

</template>

<script>
/* global gtag */

import Header from '../components/Header'

export default {
  name: 'Read',
  props: {

  },
  components: {
    Header,
  },
  computed: {
    quiz () {
      return this.$store.state.quiz
    },
    title () {
      return this.quiz.resourceTitle
    },
    resourceUrl () {
      return this.quiz.resourceUrl
    }
  },
  watch: {

  },
  apollo: {

  },
  data () {
    return {
    }
  },
  methods: {
    openResource () {
      gtag('event','open_article',{'quiz_slug':this.quiz.slug})
      window.open(this.resourceUrl)
    },
    next () {
      gtag('event','start_quiz',{'quiz_slug':this.quiz.slug})
      this.$router.push("/quiz")
    },
  },
  beforeUnmount: function () {

  },
  mounted: function() {
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/read'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  /* color: #ffffff; */
}

a {
  text-decoration:  none;
}

a:hover {
  text-decoration: underline;
}

.button {
  font-weight: bold;
  font-size: 24px;

  display: inline-block;
  padding: 12px 24px;
  margin: 30px 0 0px 0;

  background: #24C1AB;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;

}

.button:hover {
  text-decoration: none;
  background: #27d1ba;
}

.instructions {
  margin: 12px 0 0 0;
  opacity: 0.8;
  font-size: 18px;
  line-height: 22px;
}

.title {
  display: block;
  text-decoration: none;;
  font-size: 2.6em;
  line-height: 1.3em;
  font-weight: 800;
}

.small_screen .title {
  font-size: 1.5em;
}

.resource {
  position: relative;
  z-index: 100;
  padding: 40px 40px 50px 40px;

  background: #1A2C4B;
  border-radius: 29px;
  -webkit-border-radius: 29px;
  -moz-border-radius: 29px;

  color: #ffffff;
}

.small_screen .resource {
  padding: 30px;
}


.label {
  font-family: Inter-Regular_ExtraBold;
  font-size: 1.6em;
  color: #FFFFFF;
  letter-spacing: 0;
  opacity: 0.3;

  margin-bottom: 12px;
}

.small_screen .label {
  font-size: 1em;
  display:none;
}

.next {
  font-family: 'Patua One';
  font-size: 30px;
  font-weight: 500;
  color: #ff6b39;
  text-align: center;

  position: relative;
  display: inline-block;
  z-index: 100;
  margin: 40px 12px;

  background: #FFFFFF;
  border: 10px solid #FFFFFF;
  box-shadow: 0 8px 0 0 #e7e3e2, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #e7e3e2, 0 16px 15px 0 rgba(0,0,0,0.19);
}


.next:hover, .next:active, .next:focus-visible, .next:visited  {
  color: #ffffff;
  background: #ff6b39;
  border: 10px solid #ff6b39;
  box-shadow: 0 8px 0 0 #e05e33, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #e05e33, 0 16px 15px 0 rgba(0,0,0,0.19);
}

.read {
  font-family: 'Patua One';
  font-size: 30px;
  font-weight: 500;
  background-color: #24C1AB;
  color: #ffffff;
  text-align: center;
  text-decoration: none;

  position: relative;
  display: inline-block;
  z-index: 100;
  margin: 40px 12px;
  padding: 12px 36px;

  border: 10px solid #24C1AB;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  box-shadow: 0 8px 0 0 #1fb19d, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #1fb19d, 0 16px 15px 0 rgba(0,0,0,0.19);
}

.read:hover, .read:active, .read:focus-visible {
  text-decoration: none;
  background-color: #28d4bd;
  border: 10px solid #28d4bd;
  /* background: #edf5f4;
  border: 10px solid #edf5f4;
  box-shadow: 0 8px 0 0 #B7DBD6, 0 16px 15px 0 rgba(0,0,0,0.19); */
}

.small_screen .read, .small_screen .next {
  position: relative;
  display: block;
  margin: 30px 8%;
  width: 86%;
  align-items: stretch;
}
</style>
