<template lang="pug">
div.container
  Header
  .result
    .score
      span.number {{ scoreString }}
      br
      span correct
    .title {{ title }}
    div(v-if="hasReward && !canCollect")
      .instructions {{ improveInstructions }}
      button(v-on:click="go('\quiz')") Try again
    div(v-if="hasReward && canCollect")
      .instructions
        span You're eligible to receive &nbsp;
        span.bold {{quiz.reward}}
        span !&nbsp; Check for your reward by logging in with Twitter.
      a.tweet_button(:href="twitterQuizAuthorizationUrl")
        img.twitter_logo(:src="twitterLogoSrc")
        span Check for reward
    button(v-if="!hasReward", v-on:click="next") Next
</template>

<script>
/* global gtag */
import Header from '../components/Header'
import { GET_TWITTER_AUTH_URL_QUERY } from '../graphql/queries'

export default {
  name: 'Results',
  props: {

  },
  components: {
    Header,
  },
  watch: {
  },
  computed: {
    improveInstructions () {
      if (this.hasReward) return "In order to be eligible to earn the "+this.quiz.reward+" reward, you must get at least "+ Math.round(0.5*this.numQuestions)+" correct answers"
      return "Try again to improve your score."
    },
    canCollect () {
      return (this.score >= 0.5)
    },
    hasReward () {
      return this.quiz != null && this.quiz.reward != null && this.quiz.reward != ""
    },
    twitterLogoSrc () {
      return process.env.BASE_URL + "twitter_logo.svg"
    },
    quiz () {
      return this.$store.state.quiz
    },
    numQuestions () {
      return this.quiz.questions.length
    },
    numCorrect () {
      return this.quiz.numCorrect
    },
    scoreString () {
      return `${this.quiz.numCorrect} of ${this.quiz.questions.length}`
    },
    score () {
      return 1.0*this.numCorrect/this.numQuestions
    },
    title () {
      if ( this.score >= 0.5) return "🎉 Good Job!"
      return "🧐 Could be better"
    },
  },
  apollo: {
    twitterQuizAuthorizationUrl () {
      return {
        query: GET_TWITTER_AUTH_URL_QUERY,
        fetchPolicy: 'network-only',
      }
    },
  },
  data () {
    return {
      status: null,
    }
  },
  methods: {
    go (path) {
      gtag('event','results_go',{'quiz_slug':this.quiz.slug, 'path': path})
      this.$router.push(path)
    },
    next () {
      this.go("/tweet")
    },
  },
  beforeUnmount: function () {

  },
  mounted: function() {
    this.status = this.quiz.tweetTemplate ? this.quiz.tweetTemplate : null

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/results'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  /* color: #ffffff; */
}

.result {
  position: relative;
  z-index: 100;
  padding: 40px;

  background: #ffffff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  margin: 40px 10px;

  font-family: Inter;
  font-size: 36px;
  color: #1A2C4B;
  letter-spacing: 0;
}

.small_screen .result {
  padding: 20px;
}

.score {
  display: inline-block;
  line-height: 1em;
  font-size: .7em;
  padding: 34px 40px;
  background: rgba(26,44,75,0.06);
  border-radius: 29px;
  -webkit-border-radius: 29px;
  -moz-border-radius: 29px;
  font-weight: 800;
}

.score .number {
  font-size: 1.5em;
}

.small_screen .score {
  font-size: 0.6em;
  line-height: 1em;
  padding: 1.5em 1.5em 1.2em 1.5em;
}

.small_screen .score .number {
  font-size: 1.4em;
}

.title {
  margin: 30px 0 18px 0;
  font-size: 1.7em;
  font-weight: 800;
}

.small_screen .title {
  font-size: 1.1em;
}

.instructions {
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.45em;
  margin: 0px 0px 40px 0px;
}

.small_screen .instructions {
  font-size: 0.6em;
  line-height: 1.3em;
}

.input_container {
  margin-bottom: 30px;
}

.tweet_button {
  position: relative;
  display: inline-block;
  text-decoration: none;

  padding: 20px 80px;

  color: #ffffff;
  font-weight: bold;
  font-size: 36px;

  background: #1B95E0;
  box-shadow: 0 6px 0 0 #167DBD;
  -webkit-box-shadow: 0 6px 0 0 #167DBD;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
}

.tweet_button:hover {
  background: #219be7;
}

.twitter_logo {
  display: block;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translate(0,-50%);
  width: 30px;
  height: 30px;
}

.tweet_button {
  position: relative;
  z-index: 500;
  display: inline-block;
  text-decoration: none;

  padding: 20px 80px;

  color: #ffffff;
  font-weight: bold;
  font-size: 0.9em;

  background: #1B95E0;
  box-shadow: 0 6px 0 0 #167DBD;
  -webkit-box-shadow: 0 6px 0 0 #167DBD;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;

}

.small_screen .tweet_button {
  font-size: 0.5em;
  padding: 20px 36px 20px 66px;
}

button {
  position: relative;
  display: inline-block;
  text-decoration: none;

  padding: 18px 50px;

  color: #ffffff;
  font-weight: bold;
  font-size: 28px;

  background: #32d1bc;
  box-shadow: 0 6px 0 0 #24C1AB;
  -webkit-box-shadow: 0 6px 0 0 #24C1AB;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
}

button:hover {
  background: #31dfc7;
}

.small_screen button {
  font-size: 24px;
  padding: 18px 40px;
}
</style>
