<template lang="pug">
div
  div.collab_banner(v-if="quiz && collabBannerText")
    img.collab_icon(v-if="collabIconSrc", :src="collabIconSrc")
    span.collab_text {{ collabBannerText }}
  div.container
    div.rewards_banner(v-if="quiz && rewardBannerText")
      span.reward_banner_text {{rewardBannerText}}
    Header
    .quiz_content(v-if="quiz")
      img.quiz_label(:src="labelSrc")
      div.title {{quizName}}
      div.details {{quizDetails}}
      img.heroes(v-if="heroSrc", :src="heroSrc")
      button.start(v-on:click="next") Start Quiz
    .no_quiz(v-if="showNoQuiz")
      .details No quiz was found!
      a(href="/") Go home


</template>

<script>
/* global gtag */
import Header from '../components/Header'
import TwitterLoginButton from '../components/TwitterLoginButton'
import { GET_QUIZ_QUERY } from '../graphql/queries'

export default {
  name: 'Home',
  props: {
    slug: {
      type: String,
      default: null
    },
  },
  components: {
    TwitterLoginButton,
    Header,
  },
  watch: {

  },
  computed: {
    collabBannerText () {
      if (!this.quiz) return null
      return this.quiz.collabBannerText
    },
    collabIconSrc () {
      if (!this.quiz) return null
      return this.quiz.collabBannerIconUrl
    },
    merCoinSrc () {
      return process.env.BASE_URL + "mer_coin.svg"
    },
    rewardsBannerSrc () {
      return process.env.BASE_URL + "banner_bg.svg"
    },
    labelSrc () {
      return process.env.BASE_URL + "quiz_label.svg"
    },
    heroSrc () {
      if (!this.quiz) return null
      return this.quiz.homeImageUrl
    },
    quizName () {
      return this.quiz.name
    },
    quizDetails () {
      return this.quiz.description
    },
    rewardBannerText () {
      return this.quiz.rewardBannerText
    }
  },
  apollo: {
    quiz () {
      return {
        // gql query
        query: GET_QUIZ_QUERY,
        // Static parameters
        variables () {
          return {
            slug: this.slug ? this.slug : "serum"
          }
        },
        update: data => {
          if (data.quiz) {
            this.$store.dispatch('setQuiz', data.quiz)
          } else {
            this.$store.dispatch('clearQuiz')
            this.$toast.error('Quiz not found',{
              timeout: false
            })
            this.showNoQuiz = true
          }
          return data.quiz
        },
        error: (error) => {
          console.log(error)
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  data () {
    return {
      quiz: null,
      showNoQuiz: false,
    }
  },
  methods: {
    next () {
      this.$router.push("/read")
    },
  },
  beforeUnmount: function () {

  },
  mounted: function() {
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/home'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.quiz_content {
  margin: 40px 10%;
}

.small_screen .quiz_content {
  margin: 40px 4%;
}

.quiz_label {
  display: block;
  width: 130px;

  margin: 70px auto 20px auto;
}

.small_screen .quiz_label {
  width: 30%;
  max-width: 120px;
}

.title {
  font-family: 'Patua One';
  font-size: 6em;
  color: #FFFFFF;
  letter-spacing: 0;
  text-shadow: 0 5px 10px rgba(0,0,0,0.20);
  text-align: center;

  margin: 20px auto 30px auto;
}

.small_screen .title {
  font-size: 3em;
}

.details {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.4em;
  line-height: 1.3em;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 5px 10px rgba(0,0,0,0.20);

  position: relative;
  z-index: 8;
  margin: 20px auto 0px auto;
}

.small_screen .details {
  font-size: 1.3em;
}

.heroes {
  display: block;
  width: 380px;
  min-height: 50px;

  position: relative;
  z-index: 5;
  margin: 0px auto -40px auto;
}

.small_screen .heroes {

}

.start {
  font-family: "Patua One";
  font-weight: 500;
  font-size: 2.3em;
  color: #FFFFFF !important;

  position: relative;
  display: block;
  z-index: 100;
  padding: 30px 100px;
  margin: 60px auto;

  background: #283D62;
  box-shadow: 0 8px 0 0 #1F3255, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #1F3255, 0 16px 15px 0 rgba(0,0,0,0.19);
}

.start:hover {
  background: #2d456e;
}

.small_screen .start {
  font-size: 1.8em;
  padding: 20px 40px;
  margin: 40px auto;
}

.no_quiz a {
  font-family: Inter-Regular_Bold;
  font-size: 1.3em;
  color: #FFFFFF !important;

  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 20px 40px;
  margin: 60px auto;

  background: #283D62;
  box-shadow: 0 8px 0 0 #1F3255, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #1F3255, 0 16px 15px 0 rgba(0,0,0,0.19);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  text-decoration: none;
}

.no_quiz a:hover {
  background: #2d456e;
}

.rewards_banner {
  position: relative;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  padding: 18px 32px;
  background-color: #07151aa6;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  margin: 0 auto 30px auto;

  left: 50%;
  transform: translateX(-50%);
}

.small_screen .rewards_banner {
  padding: 14px 28px;
  margin-bottom: 20px;
}

.reward_banner_text {
  color: #d6fff8;
  font-weight: 400;
  font-size: 22px;
  font-family: 'Patua One';
}

.small_screen .reward_banner_text {
  font-size: 18px;
}

.mercoin {
  display: inline-block;
  position: relative;
  width: 36px;
}

.collab_banner {
  position: relative;
  z-index: 20;
  background: #0A1528;
  height: 50px;
}

.collab_icon {
  display: inline-block;
  position: relative;
  height: 40px;
  margin: 0 20px 0 10px;
  top: 50%;
  transform: translateY(-50%);
}

.collab_text {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #93A4C1;

  position: absolute;

  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}
</style>
