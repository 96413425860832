<template lang="pug">
div.header_container
  a.logo_container.clickable(:href="homeUrl")
    img.logo(:src="logoSrc")
</template>

<script>


export default {
  name: 'Header',
  props: {

  },
  components: {

  },
  computed: {
    quizSlug () {
      if (!this.$store.state.quiz) return ""
      return this.$store.state.quiz.slug
    },
    homeUrl () {
      if (this.$route.name == "Home") return "https://mercurial.finance"
      return "/"+this.quizSlug
    },
    logoSrc () {
      return process.env.BASE_URL + "logo.svg"
    },
  },
  apollo: {

  },
  data () {
    return {
    }
  },
  methods: {

  },
  beforeDestroy() {
  },
  beforeUnmount: function () {

  },
  mounted: function() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header_container {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 40px;
}
.logo_container {
  display: block;
  width: 120px;
  margin: 18px auto 0px auto;
}
.logo {
  width: 100%;
}

</style>
