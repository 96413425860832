<template lang="pug">
div.login_container
  a.loginbutton.clickable(v-on:click="doTwitterLogin" v-if="showLogin")
    span Schedule this post with Twitter
    img.twitterlogo(:src="twitterLogoSrc")
  div.clear

</template>

<script>

import { GET_TWITTER_AUTH_URL_QUERY } from '../graphql/queries'

export default {
  name: 'TwitterLoginButton',
  props: {
    auto: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  computed: {
    twitterLogoSrc () {
      return process.env.BASE_URL + "twitter_logo.svg"
    },
    showLogin () {
      return this.twitterBotAuthorizationUrl != undefined
    },

  },
  watch: {
    auto () {
      if (this.auto) this.doTwitterLogin()
    },
  },
  apollo: {
    twitterBotAuthorizationUrl () {
      return {
        query: GET_TWITTER_AUTH_URL_QUERY,
        fetchPolicy: 'network-only',
      }
    },
  },
  data () {
    return {

    }
  },
  methods: {
    doTwitterLogin: function() {
      window.location.href = this.twitterBotAuthorizationUrl
    },
  },
  beforeUnmount: function () {

  },
  mounted: function() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_container {
  position: relative;
  margin: 30px auto;
  z-index: 1000;
}

.twitterlogo {
  width: 40px;
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.loginbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  margin: 0 auto;
  cursor: pointer;
  background-color: #f4f4f74b;
  border-radius: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  font-weight: 800;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  padding: 30px 76px !important;
  text-decoration: none;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.loginbutton:hover {
  background-color: #f4f4f738;
}
</style>
