<template lang="pug">
div.container
  Header
  .question
    .title {{text}}
    .progressbar
      k-progress(
        :line-height="6",
        :percent="percentProgress",
        type="line",
        :show-text="false",
        color="#48C0AA",
        bg-color="#273a5c",
      )
  .choices
    a.choice(v-for="(choice, index) in choices", v-on:click="select(choice)", v-bind:class="{selected: selected == choice}")
      .option(v-bind:class="{is_correct: answered && choice.isCorrect, is_wrong: selectedWrong(choice), fadeOut: (answered && !choice.isCorrect && selected != choice)}")
        img.correct_icon(:src="correctIconSrc", v-if="answered && choice.isCorrect")
        img.wrong_icon(:src="wrongIconSrc", v-if="selectedWrong(choice)")
        span {{ option(index) }}
      .text(v-bind:class="{fadeOut: (answered && !choice.isCorrect && selected != choice)}") {{ choice.text }}
  button.next(v-on:click="nextQuestion", v-if="showNextButton") Next

</template>
<script>
/* global gtag */

import Header from '../components/Header'

export default {
  name: 'Quiz',
  props: {

  },
  components: {
    Header,
  },
  computed: {
    correctIconSrc () {
      return process.env.BASE_URL + "checkmark_icon.svg"
    },
    wrongIconSrc () {
      return process.env.BASE_URL + "x_icon.svg"
    },
    quiz () {
      return this.$store.state.quiz
    },
    currentQuestion () {
      return this.quiz.questions[this.index]
    },
    text () {
      return this.currentQuestion.text
    },
    numQuestions () {
      return this.quiz.questions.length
    },
    percentProgress () {
      return Math.round(100*(this.index+1)/this.numQuestions)
    },
    progress () {
      return `${this.index+1} / ${this.numQuestions}`
    },
    choices () {
      return this.currentQuestion.choices
    },
    answered () {
      return this.selected != null
    },
  },
  watch: {

  },
  apollo: {

  },
  data () {
    return {
      index: 0,
      correct: 0,
      selected:null,
      showNextButton: false,
    }
  },
  methods: {
    selectedCorrect (choice) {
      return this.answered && this.selected == choice && this.selected.isCorrect
    },
    selectedWrong (choice) {
      return this.answered && this.selected == choice && !this.selected.isCorrect
    },
    nextQuestion () {
      if (this.index >= (this.numQuestions-1)) {
        this.quiz.numCorrect = this.correct
        this.$store.dispatch('setQuiz', this.quiz)
        this.$router.push("/results")
      } else {
        this.index += 1
        this.selected = null
      }

      this.showNextButton = false
    },
    select(choice) {
      if (this.showNextButton) return

      this.selected = choice

      if (choice.isCorrect) {
        this.correct += 1
      }

      this.showNextButton = true

      gtag('event','selected_answer',{'quiz_slug':this.quiz.slug, 'question': this.currentQuestion.order, 'is_correct': choice.isCorrect})
    },
    option (index) {
      return String.fromCharCode(65+index)
    },
    back () {
      if (this.index > 0) {
        this.index--
      } else {
        this.$router.go(-1)
      }
    },
  },
  beforeUnmount: function () {

  },
  mounted: function() {
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/quiz'})
  }
}
</script>

<style>
.k-progress-outer {
  padding-right: 0px !important;
}

</style>
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.question {
  position: relative;
  z-index: 100;
  padding: 40px 40px 20px 40px;

  background: #1A2C4B;
  border-radius: 29px;
  -webkit-border-radius: 29px;
  -moz-border-radius: 29px;

  color: #ffffff;
}

.small_screen .question {
  padding: 30px 30px 20px 30px;
}

.progressbar {
  margin-top: 38px;
  width: 100%;
}

.title {
  display: block;
  text-decoration: none;
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 800;
}

.small_screen .title {
  font-size: 1.6em;
  line-height: 1.2em;
}

.label {
  font-family: Inter-Regular_ExtraBold;
  font-size: 1.4em;
  color: #FFFFFF;
  opacity: 0.3;

  margin-bottom: 12px;
}

.small_screen .label {
  font-size: 1em;
}

.choices {
  position: relative;
  z-index: 100;
  padding: 30px 40px 20px 40px;

  background: #ffffff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  margin: 40px 0 30px 0;
}

.small_screen .choices {
  margin: 40px 0 110px 0;
  padding: 12px;
}

.choice {
  display: block;
  position: relative;

  margin: 0px 0 20px 0;
  color: #1A2C4B;
  background-color: #FFFFF;
  padding: 20px 40px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;

  cursor: pointer;
}

.choice:hover {
    text-decoration: none;
  }

@media (hover: hover) {
  .choice:hover {
    background-color: #F1F3F6;
  }
}

.small_screen .choice {
  margin: 0px 0 10px 0;
  padding: 20px 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

@media (hover: hover) {
  .small_screen .choice:hover {
    background-color: #FFFFF;
  }
}

.selected {
  background-color: #F1F3F6;
}

.choice .text {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-left: 40px;
}

a {
  display:block;
}

.option {
  position: absolute;
  left: 10px;
  top: 34px;
  transform: translate(0%, -50%);
  font-weight: bold;
  font-size: 18px;
  text-align: center;

  background: #1A2C4B;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  color: #ffffff;
}

.is_correct {
  background: #24C1AB;
  color: #24C1AB;
}

.is_wrong {
  background: #ED663A;
  color: #ED663A;
}

.correct_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrong_icon {
  width: 16px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fadeOut {
  opacity: 0.1;
}

button.next {
  font-family: 'Patua One';
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;

  position: relative;
  display: inline-block;
  z-index: 100;
  margin: 0 0 20px 0;

  left: 50%;
  transform: translateX(-50%);

  background: #24C1AB;
  border: 10px solid #24C1AB;
  box-shadow: 0 8px 0 0 #23aa98, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #23aa98, 0 16px 15px 0 rgba(0,0,0,0.19);
}

.small_screen button.next {
  position: fixed;
  bottom: 20px;
}

button.next:hover, button.next:active, button.next:focus-visible {
  color: #ffffff;
  background: #2ad1bb;
  border: 10px solid #2ad1bb;
  box-shadow: 0 8px 0 0 #23aa98, 0 16px 15px 0 rgba(0,0,0,0.19);
  -webkit-box-shadow: 0 8px 0 0 #23aa98, 0 16px 15px 0 rgba(0,0,0,0.19);
}
</style>
