<template lang="pug">
div.container
  Header
  .reward_details(v-if="token && checkRewardStatus")
    span.status(v-bind:class="{warning: checkRewardStatus && !checkRewardStatus.earned}") {{ checkRewardStatus.message }}
    a.claim_link.clickable(v-if="checkRewardStatus && checkRewardStatus.earned", v-on:click="openClaimReward") Claim your reward
  .tweet_container(v-if="instructions")
    .title {{title}}
    div.tweet_instructions
      .instructions(v-if="instructions") {{instructions}}
      div.input_container(v-if="status")
        input(v-model="status" type="text" placeholder="Type in your tweet"  ref="code")
      a.tweet_button(v-on:click="openTweet")
        img.twitter_logo(:src="twitterLogoSrc")
        span Tweet
</template>

<script>
/* global gtag */
import Header from '../components/Header'
import { CHECK_REWARD_STATUS_QUERY } from '../graphql/queries'

export default {
  name: 'Tweet',
  props: {
    token: {
      type: String,
      default: null
    },
  },
  components: {
    Header,
  },
  watch: {
  },
  computed: {
    tweetUrl () {
      var url =  "https://twitter.com/intent/tweet"
      if (this.status) url += "?text=" + encodeURIComponent(this.status)
      return url
    },
    title () {
      if (this.quiz.hasTweetRewards) return "Tweet for more rewards"
      return "Tweet this"
    },
    communityPortalUrl () {
      return process.env.VUE_APP_COMMUNITY_PORTAL
    },
    twitterLogoSrc () {
      return process.env.BASE_URL + "twitter_logo.svg"
    },
    quiz () {
      return this.$store.state.quiz
    },
    reward () {
      return this.quiz.reward
    },
    instructions () {
      return this.quiz.tweetInstructions
    },
  },
  apollo: {
    checkRewardStatus () {
      return {
        // gql query
        query: CHECK_REWARD_STATUS_QUERY,
        // Static parameters
        variables () {
          return {
            token: this.token,
            quizSlug: this.quiz.slug,
          }
        },
        update: data => {
          return data.checkRewardStatus
        },
        error: (error) => {
          console.log(error)
          this.$toast.error(error)
        },
        skip () {
          return !this.token || !this.quiz || !this.quiz.slug
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  data () {
    return {
      status: null,
    }
  },
  methods: {
    openTweet () {
      gtag('event','click_tweet_button',{'quiz_slug':this.quiz.slug})
      window.open(this.tweetUrl)
    },
    openClaimReward () {
      gtag('event','click_claim_reward',{'quiz_slug':this.quiz.slug})
      window.open(this.communityPortalUrl)
    },
  },
  beforeUnmount: function () {

  },
  mounted: function() {
    this.status = this.quiz.tweetTemplate ? this.quiz.tweetTemplate : null

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/tweet'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tweet_container {
  position: relative;
  z-index: 100;
  padding: 40px;

  background: #ffffff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  margin: 20px 10px 40px 10px;

  font-family: Inter;
  font-size: 36px;
  color: #1A2C4B;
  letter-spacing: 0;
}

.title {
  margin: 0px 0 14px 0;
  font-size: 1.1em;
  font-weight: 800;
}

.bold {
  font-weight: bold;;
}

.tweet_button {
  position: relative;
  z-index: 500;
  display: inline-block;
  text-decoration: none;

  padding: 20px 50px 20px 70px;

  color: #ffffff;
  font-weight: bold;
  font-size: .8em;

  background: #1B95E0;
  box-shadow: 0 6px 0 0 #167DBD;
  -webkit-box-shadow: 0 6px 0 0 #167DBD;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;

  margin: 12px 0;
}

.tweet_button:hover {
  background: #219be7;
}

.twitter_logo {
  display: block;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translate(0,-50%);
  width: 30px;
}

.small_screen .twitter_logo {
  width: 24px;
}

.small_screen .tweet_button {
  font-size: 0.6em;
  padding: 20px 50px 20px 60px;
}


.reward_details {
  display: block;
  position: relative;
  padding: 16px 16px;
  margin: 10px 10px 10px 10px;

  background: #fffedb;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  font-size: 20px;
  line-height: 26px;
}

.status {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #1A2C4B;
  line-height: 20px;
}

.small_screen .status {
  display: block;
}

.warning {
  color: #ED663A;
}

.claim_link {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: bold;
  background-color: #1A2C4B;
  color: #fffedb;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  text-decoration: none;
}

.claim_link:hover {
  background-color: #213558;
}

.small_screen .claim_link {
  position: relative;
  margin: 10px 0 0px 0;
  transform: none;
  top: auto;
  right: auto;
}

.instructions {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 1.2em;
}
</style>
