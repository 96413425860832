import gql from 'graphql-tag'

export const GET_QUIZ_QUERY = gql`
  query ($slug: String) {
    quiz(slug: $slug) {
      slug
      name
      description
      homeImageUrl

      reward

      resourceUrl
      resourceTitle

      tweetInstructions
      tweetTemplate
      hasTweetRewards

      rewardBannerText

      collabBannerText
      collabBannerIconUrl

      questions {
        text
        order
        choices {
          text
          isCorrect
        }
      }
    }
  }
`
export const GET_TWITTER_AUTH_URL_QUERY = gql`
  query {
    twitterQuizAuthorizationUrl
  }
`

export const CHECK_REWARD_STATUS_QUERY = gql`
  query ($token: String!, $quizSlug: String!) {
    checkRewardStatus(token: $token, quizSlug: $quizSlug) {
      earned
      message
    }
  }
`

